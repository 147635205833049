enum LangType {
  EN = "en-US",
  ZH = "zh-Hans",
  // KO = "ko-KR",
  HK = "zh-Hant-HK",
  TW = "zh-Hant-TW",
  TH = "th-TH",
}

// moment i18n
const momentLangMap = {
  [LangType.EN]: "en",
  [LangType.ZH]: "zh-cn",
  // [LangType.KO]: "ko",
  [LangType.HK]: "zh-hk",
  [LangType.TW]: "zh-tw",
  [LangType.TH]: "th",
};

// 支持的语言 LangType.KO,
const languages = [
  LangType.EN,
  LangType.ZH,
  LangType.HK,
  LangType.TW,
  LangType.TH,
];

// 语言映射
const languagesMap: [RegExp, LangType][] = [
  // tw
  [/^zh-tw/i, LangType.TW],
  // hk
  [/^zh-hk/i, LangType.HK],
  [/^zh-hant/i, LangType.HK],
  // cn
  [/^zh-hans/i, LangType.ZH],
  [/^zh/i, LangType.ZH],
  // en
  [/^en/i, LangType.EN],
  // ko
  // [/^ko/i, LangType.KO],
  // th
  [/^th/i, LangType.TH],
];

const isSpaceCN = () => {
  return /switchspace.cn/.test(document.domain);
};

// 默认语言
const defaultLanguage: LangType = isSpaceCN() ? LangType.ZH : LangType.EN;

// 选择语言
const LangSelects: { value: LangType; text: string }[] = [
  {
    value: LangType.EN,
    text: "English",
  },
  {
    value: LangType.ZH,
    text: "简体中文",
  },
  {
    value: LangType.HK,
    text: "繁體中文(HK)",
  },
  {
    value: LangType.TW,
    text: "繁體中文(TW)",
  },
  {
    value: LangType.TH,
    text: "ยาสุฟุมิ",
  },
  // {
  //   value: LangType.KO,
  //   text: "한국어",
  // },
];

export {
  LangType,
  languages,
  languagesMap,
  defaultLanguage,
  LangSelects,
  momentLangMap,
};
